<template>
  <div>
    <!-- header -->
    <CurvedHeader
      title="HISTORY"
      icon="history" />
    <!-- <CurvedHeader title="HISTORY" /> -->

    <!-- reminder card -->
    <div class="reminder-card pa-4">
      <div class="d-flex justify-space-between align-center mb-4">
        <v-btn
          elevation="0"
          style="background-color: transparent"
          class="text-capitalize"
          @click="goBackToProfile">
          <v-icon
            class="mr-2"
            color="primaryColor"
            >mdi-arrow-left</v-icon
          >
          Back to profile
        </v-btn>

        <v-btn
          @click="openCourseFiltersDialog"
          icon>
          <v-img :src="require('@/assets/course/filtersIcon.png')"></v-img>
        </v-btn>
      </div>

      <!-- clear -->
      <v-row justify="end">
        <v-btn
          v-if="historyCourses.length"
          color="transparent"
          elevation="0"
          id="clear-history-button"
          class="text-capitalize"
          @click="clearHistory"
          :loading="isClearingHistory"
          >clear history</v-btn
        >
      </v-row>

      <!-- history -->
      <!-- loading -->
      <div
        v-if="isLoadingCourses"
        class="my-12">
        <v-progress-linear
          indeterminate
          color="primaryColor"></v-progress-linear>
      </div>
      <!-- courses -->
      <div v-else>
        <div
          v-for="course in filteredCourses"
          :key="course.id">
          <CourseRowCard :course="course" />
        </div>
        <div
          class="mt-14 text-center"
          v-if="!historyCourses.length">
          No History found...
        </div>
      </div>
    </div>

    <v-bottom-sheet
      v-model="showCourseFiltersDialog"
      bottom>
      <course-filters-dialog
        :mediaType="mediaType"
        :maxTime="maxTime"
        @closeDialog="closeCourseFiltersDialog"
        @setMediaType="setMediaType"
        @setMaxTime="setMaxTime"
        @filterCourses="filterCourses(historyCourses)"
        @clearAllFilters="resetCourses(historyCourses)" />
    </v-bottom-sheet>
  </div>
</template>

<script>
  import HistoryMixin from '@/views/profile/history/History.mixin.vue';
  export default {
    mixins: [HistoryMixin],
  };
</script>

<style scoped>
  #clear-history-button {
    color: var(--v-errorDark-base);
    font-size: 0.8rem;
  }
</style>
